<template>
  <div style="padding-top: 10px" class="mobile-info-card">
    <CCard accent-color="primary">
      <CCardHeader color="white">
        <!-- <b class="text-dark">Load line {{ truck ? truck.dn_number : null}}</b> -->
        <div style="display: flex;">
          <div>
           <label style="margin-bottom: 0; margin-right: 10px; line-height: calc(1.5em + 0.75rem + 2px);">Trucks</label>
          </div>
          <div>
            <CSelect
              :options="truckPlates"
              :value.sync="selectedTruckPlate"
            />
          </div>
        </div>

   
      </CCardHeader>
      <CCardBody>
        <Accordion :activeIndex.sync="activeIndex">
          <AccordionTab >
            <template #header>
              <span>General</span>
            </template>
            <p>
              Order number: <b>{{ truck ? truck.id_order : null }}</b>
            </p>
            <p>
              Load line: <b>{{  truck ? truck.dn_number  : null}}</b>
            </p>
            <p>
              CNN: <b>{{  truck ? truck.cnn  : null}}</b>
            </p>
            <p>
              External Id: <b>{{  truck ? formatExternalId(truck.external_id)  : null}}</b>
            </p>
            <p>
              Plant: <b>{{  truck ? truck.plant  : null}}</b>
            </p>
            <p>
              Ship to: <b>{{  truck ? truck.ship_to_name  : null}}</b>
            </p>
          </AccordionTab>
          <AccordionTab header="Timings">
            <p>
              Weight In: <b>{{  truck ? `${moment(truck.first_weight_date).format("DD/MM/YYYY HH:mm")}` : 'Unknown'}}</b>
            </p>
            <p>
              Weight Out: <b>{{  truck ? `${moment(truck.second_weight_date).format("DD/MM/YYYY HH:mm")}` : 'Unknown'}}</b>
            </p>
            <p>Remaining time: <b>{{travelInfo? moment.duration(travelInfo.pendingTime, "seconds").humanize():null}}</b></p>
            <p>Remaining distance: <b>{{travelInfo ? convertDistanceToString(travelInfo.pendingLength, truck.uom):null}}</b></p>
            <p>Geoposition timestamp: <b>{{truck ? `${moment(truck.position_timestamp).format("DD/MM/YYYY HH:mm")}` : null}}</b></p>
          </AccordionTab>
          <AccordionTab header="Materials">
            <p style="white-space: normal;">
              Material: <b>{{  truck ? `${truck.material}` : 'Unknown'}}</b>
            </p>
            <p>
              Load Quantity: <b>{{  truck ? `${parseFloat(truck.load_net_weight).toFixed(2)} TM` : 'Unknown'}}</b>
            </p>
            <p>
              Delivered Order Quantity: <b>{{  truck ? `${truck.delivered_quantity} TM`  : 'Unknown'}}</b>
            </p>
            <p>
              Remaining Order Quantity: <b>{{  truck ? `${(truck.total_ordered - truck.delivered_quantity).toFixed(2)} TM`  : 'Unknown'}}</b>
            </p>
            <p>
              Total Order Quantity: <b>{{  truck ? `${truck.total_ordered} TM` : 'Unknown'}}</b>
            </p>
          </AccordionTab>
        </Accordion>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import Vue from "vue"
import * as Socket from "../plugins/socket_client";
import { store } from "../store/store";
import moment from 'moment';
import * as gmaps from "../plugins/here";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
export default {
  name: "Map",
  components: {
    Accordion, 
    AccordionTab 
  },
  props: {},
  computed: {
    truck() {
      return this.trucks.find(x=>x.truck_plate == this.selectedTruckPlate)
  
    },
    truckPlates() {
      return store.state.trucks.filter(x=>x.mode =='dn').map(x=>x.truck_plate);
    },
    trucks() {
      // gmaps.calculateDNVisibility(['all'], true, this.truckPlates)
      return store.state.trucks.filter(x=>x.mode =='dn');
    },
    // travelInfo() {
    //   return gmaps.getTravelInfoTruck(this.truck.truck_plate);
    // },
    markers() {
      return store.state.markers;
    },
    // statusFilter() {
    //   return store.state.filters.deliveryNoteStatus;
    // }
  },
  watch: {
    //   marker: {
    //   deep: true,
      
    //   immediate: true,
    //   handler: function () {
    //     if(this.selectedTruckPlate){
    //       this.updateTravelInfo();
    //     }
    //   },
    // },
    selectedTruckPlate: {  
      immediate: true,
      handler: function () {

      

        if(this.selectedTruckPlate){
          this.updateTravelInfo();
        }
       
      },
    },
    activeIndex: {
      handler: function (e) {
        window.dispatchEvent(new Event('resize'));
        this.sleep(300).then(() => {
          window.dispatchEvent(new Event('resize'));
        });
      }
    },
    trucks: {
      deep: true,
      
      immediate: true,
      handler: function () {
       if(!this.selectedTruckPlate && this.truckPlates[0]){
        this.selectedTruckPlate = this.truckPlates[0]
       }
      },
    },

   },
  data() {
    return {
      //infoCamion: {},
      moment:moment,
      travelInfo:null,
      selectedTruckPlate: null,
      activeIndex: null
    };
  },
  created() {
  },

  

  methods: {
   updateTravelInfo(){
          gmaps.setTruckPlateRoute( this.truck.truck_plate, false);
          gmaps.highlightTruck(this.truck.truck_plate)
        this.travelInfo = gmaps.getTravelInfoTruck(this.truck.truck_plate);
    },
    formatExternalId(extId){
      if(extId){
        let extIdArr = extId.split('|');
        return extIdArr.length > 1 ? extIdArr[1] : extId;
      }else{
        return extId;
      }
    }
  }
};
</script>


<style lang="scss">
.mobile-info-card {
  font-size: 1rem;
  .card{
    border-radius: 0;
    border: 0 !important;
  }

  .p-accordion {
    font-size: 1rem;
  }
}
.mobile-info-card .card-header {
  font-size: 0.9rem;
}
.mobile-info-card .card-body p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3rem;
  margin-bottom: 5px;
}
.mobile-info-card > div{
  margin-bottom: 0px;
}
</style>
<style scoped lang="scss">
.form-group{margin-bottom: 0;}
</style>