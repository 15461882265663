<template>
  <div class="window-body">
    <BlockUI
      id="loading"
      :message="$t('loading')"
      v-if="loading"
      html="<i class='fa fa-cog fa-spin fa-2x fa-fw'></i>"
    />
    <span v-show="!trucksAvailable"  class="blur-background">
      <span class="no-truck-available"
        >Delivery not available</span
      >
    </span>
    <CRow class="flex_80">
      <CCol xl="12" lg="12">
        <Map :mode="'mobile'" />
      </CCol>
    </CRow>
    <CRow class="flex_20">
      <CCol xl="12" lg="12">
        <MobileInfoCard />
      </CCol>
    </CRow>

    <CToaster :autohide="3000" class="main-toast">
      <CToast :key="'toast'" :show="toastShow" :color="toastColor">
        {{ toastMessage }}
      </CToast>
    </CToaster>
  </div>
</template>

<script>
import Map from "../components/Map.vue";
import MobileInfoCard from "../components/MobileInfoCard.vue";
import * as Socket from "../plugins/socket_client";
import { store } from "../store/store";
export default {
  name: "MobileDashboard",
  components: {
    Map,
    MobileInfoCard,
  },
  computed: {
     trucksAvailable() {
      return store.state.trucks.filter(x=>x.mode =='dn').length >0;
    },
    
  },
  data() {
    return {
      loading: true,

      // props toast
      toastShow: false,
      toastColor: "",
      toastMessage: "",
    };
  },
  created() {
    Socket.connect();
  },
  async mounted() {
    this.loading = false;
    
    window.history.replaceState(
      null,
      null,
      window.location.toString().split("?")[0]
    );
  },
  methods: {
    showToastError(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "danger";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
    showToastInfo(msg) {
      const self = this;
      self.toastShow = true;
      self.toastColor = "success";
      self.toastMessage = msg;

      self.sleep(3000).then(() => {
        self.toastShow = false;
      });
    },
    processQueryFilters(query) {
      for (var key of Object.keys(query)) {
        var values = query[key].split(",");
        for (var value of values) {
          this.addFilter(value, key);
        }
      }
    },
    addFilter(data, type) {
      store.commit("addFilter", { type: type, value: data });
      Socket.add(type, data);
    },
  },
};
</script>

<style lang="scss" >
.main-toast {
  z-index: 11000;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.window-body {
  padding: 2em;
  height: 100vh;
  flex-direction: column;
  display: flex;
  align-items: stretch;
}
.map_parent {
  height: 100% !important;
  max-height: 100% !important;
}
.flex_80 {
  display: flex;
  flex-basis: 80%;
}
.flex_20 {
  display: flex;
}
.height_100 {
  height: 100%;
}
.height_80 {
  height: 80%;
}
.height_20 {
  height: 20%;
}

.blur-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100;
}
.blur-background > .no-truck-available {
  display: inline-block;
  position: relative;
  z-index: 1;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 3em;
  line-height: 1.5;
  white-space: nowrap;
  max-width: 80%;
  width: auto;
  text-align: center;
  border: 2px solid var(--primary) !important;
}
</style>